import { FormattedMessage } from 'react-intl';
import { WarningRhombusFilledIcon } from '@pledge-earth/product-language';
import type { ComponentPropsWithoutRef } from 'react';

import { ErrorBase } from '../Base/ErrorBase';

export function Error500(props: Omit<ComponentPropsWithoutRef<typeof ErrorBase>, 'icon' | 'title' | 'description'>) {
  return (
    <ErrorBase
      image={<WarningRhombusFilledIcon />}
      title={<FormattedMessage id="auth.500.title" />}
      description={<FormattedMessage id="auth.500" />}
      {...props}
    />
  );
}
